import {
  Menu,
  MenuButton,
  MenuItem,
  MenuItems,
  Transition
} from '@headlessui/react'
import { ChevronRightIcon } from '@heroicons/react/24/solid'
import PropTypes from 'prop-types'
import { Fragment } from 'react'
import { useTranslation } from 'react-i18next'

import trackEvents from '../../../constants/track-events'
import { classNames } from '../../../helpers'
import { trackEvent } from '../../../helpers/analytics'

const CompareSpaceDropdown = ({
  searchSpaceQuery,
  setSearchSpaceQuery,
  spaces,
  filteredSpaces,
  setCompareSpace,
  setDisplayModal
}) => {
  const { t } = useTranslation()

  return (
    <Menu as='div' className='relative mt-2 w-full text-left'>
      <MenuButton className='w-full rounded-full bg-white px-3.5 py-2 text-sm font-semibold text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 hover:bg-gray-50'>
        {t('space.radar_chart.compare_action')}
      </MenuButton>

      <Transition
        as={Fragment}
        enter='transition ease-out duration-100'
        enterFrom='transform opacity-0 scale-95'
        enterTo='transform opacity-100 scale-100'
        leave='transition ease-in duration-75'
        leaveFrom='transform opacity-100 scale-100'
        leaveTo='transform opacity-0 scale-95'
      >
        <div className='absolute right-0 z-10 w-full origin-top-right'>
          <MenuItems className='mt-2 w-full'>
            {!!spaces.length && (
              <input
                type='text'
                className='w-full rounded-md border-0 bg-white py-1.5 pl-3 pr-12 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6'
                onChange={(event) => setSearchSpaceQuery(event.target.value)}
                value={searchSpaceQuery}
              />
            )}
            <div className='mt-1 max-h-48 divide-y divide-gray-100 overflow-y-auto rounded-md bg-white py-2 shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none'>
              {!spaces.length && (
                <MenuItem>
                  <span className='block px-4 py-2 text-center text-sm italic text-gray-700'>
                    {t('space.radar_chart.compare_action_empty_spaces_hint')}
                  </span>
                </MenuItem>
              )}
              {!filteredSpaces.length && !!spaces.length && (
                <MenuItem>
                  <span className='block px-4 py-2 text-center text-sm italic text-gray-700'>
                    {t('space.radar_chart.compare_action_filtered_spaces_hint')}
                  </span>
                </MenuItem>
              )}
              {filteredSpaces.map((space) => (
                <MenuItem key={space.id}>
                  {() => (
                    <button
                      onClick={() => {
                        trackEvent(trackEvents.CLICK_COMPARE_RADARCHART_TALENT)
                        setCompareSpace(space)
                        setDisplayModal(true)
                      }}
                      className={classNames(
                        'group relative flex w-full whitespace-nowrap px-4 py-2 text-left text-sm text-gray-700',
                        'hover:bg-gray-50 hover:text-gray-900'
                      )}
                    >
                      <span className={classNames('font-semibold')}>
                        {space.name}
                      </span>
                      <span className={classNames('ml-1 truncate italic')}>
                        {space.identification}
                      </span>
                      <span
                        className={classNames(
                          'hidden items-center group-hover:flex',
                          'absolute inset-y-0 right-0 pr-2 text-gray-900'
                        )}
                      >
                        <ChevronRightIcon
                          className='h-4 w-4'
                          aria-hidden='true'
                        />
                      </span>
                    </button>
                  )}
                </MenuItem>
              ))}
            </div>
          </MenuItems>
        </div>
      </Transition>
    </Menu>
  )
}

CompareSpaceDropdown.propTypes = {
  searchSpaceQuery: PropTypes.string.isRequired,
  setSearchSpaceQuery: PropTypes.func.isRequired,
  spaces: PropTypes.array.isRequired,
  filteredSpaces: PropTypes.array.isRequired,
  setCompareSpace: PropTypes.func.isRequired,
  setDisplayModal: PropTypes.func.isRequired
}

export default CompareSpaceDropdown
