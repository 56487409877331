import PropTypes from 'prop-types'

import { useLanguageContext } from '../../../context'
import { hexToRGB } from '../../../helpers'
import { useChartData } from '../../../hooks/use-chart-data'
import RadarChart from '../../react-chart/radar-chart'

const RadarChartPreview = ({ space }) => {
  const { language } = useLanguageContext()

  const { extractChartData, options } = useChartData({ spaces: [space] })

  const chartData = extractChartData()

  return (
    <div className='relative h-auto w-full'>
      <RadarChart
        data={{
          labels: chartData.dimensions.map(
            ({ property }) => property[language]
          ),
          datasets: chartData.spaces.map((space) => ({
            ...space,
            data: space.data.map(({ result }) => result)
          }))
        }}
        options={{
          responsive: true,
          maintainAspectRatio: true,
          aspectRatio: 1.2,
          scales: {
            r: {
              ...options.scales.r,
              pointLabels: { display: false },
              ticks: {
                ...options.scales.r.ticks,
                backdropColor: hexToRGB('#f9fafb')
              }
            }
          },
          plugins: {
            legend: {
              display: false
            },
            tooltip: {
              enabled: false
            },
            // disable all animations
            animation: false
          }
        }}
      />
    </div>
  )
}

RadarChartPreview.propTypes = { space: PropTypes.object.isRequired }

export default RadarChartPreview
