import { InformationCircleIcon, XMarkIcon } from '@heroicons/react/24/solid'
import PropTypes from 'prop-types'
import { useState } from 'react'
import { useTranslation } from 'react-i18next'

import trackEvents from '../../constants/track-events'
import { useUserContext } from '../../context'
import { trackEvent } from '../../helpers/analytics'

const SpaceSetStateHint = ({ space, spaceActions }) => {
  const { t } = useTranslation()
  const { active_space } = useUserContext()

  const [hideStateHintImmediately, setHideStateHintImmediately] =
    useState(false)

  const {
    id: space_id,
    smart_predict_status: {
      key: smart_predict_key,
      status: { id: smart_predict_status_id }
    }
  } = space

  const lastStateIsAlreadySmartPredict =
    smart_predict_key === 'SMART_PREDICT' &&
    smart_predict_status_id !== 'ASSESSMENT_COMPLETED' &&
    smart_predict_status_id !== 'INTEGRATION_SUCCESS' &&
    smart_predict_status_id !== 'INTEGRATION_ERROR'

  const state_already_seen_or_set =
    active_space.ui_space_state_hint[space_id] === 'seen' ||
    lastStateIsAlreadySmartPredict

  if (hideStateHintImmediately) return null
  if (state_already_seen_or_set) return null

  const hideSetSpaceStateHint = () => {
    setHideStateHintImmediately(true)
    trackEvent(trackEvents.HIDE_SPACE_SET_STATE_HINT)
    active_space.updateUiSettingsSpaceStateHint({
      key: space_id,
      value: 'seen'
    })
  }

  return (
    <div className='rounded-md bg-blue-50 p-4'>
      <div className='flex items-center'>
        <div className='flex-shrink-0'>
          <InformationCircleIcon
            className='h-5 w-5 text-blue-400'
            aria-hidden='true'
          />
        </div>
        <div className='ml-3 flex-1 md:flex md:justify-between'>
          <p className='text-sm text-blue-700'>
            {t('space.space_set_state_hint.title')}
          </p>
          <p className='mt-3 text-sm md:ml-6 md:mt-0'>
            <span
              onClick={() => spaceActions.setDisplaySetSpaceState(true)}
              className='cursor-pointer whitespace-nowrap font-medium text-blue-700 hover:text-blue-600'
            >
              {t('space.space_set_state_hint.action')}
            </span>
          </p>
        </div>
        <div
          onClick={hideSetSpaceStateHint}
          className='group ml-3 flex-shrink-0 cursor-pointer rounded-full'
        >
          <XMarkIcon
            className='h-5 w-5 text-blue-700 group-hover:text-blue-600'
            aria-hidden='true'
          />
        </div>
      </div>
    </div>
  )
}

SpaceSetStateHint.propTypes = {
  space: PropTypes.object.isRequired,
  spaceActions: PropTypes.object.isRequired
}

export default SpaceSetStateHint
