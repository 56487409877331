import {
  Dialog,
  DialogPanel,
  DialogTitle,
  Transition,
  TransitionChild
} from '@headlessui/react'
import { XMarkIcon } from '@heroicons/react/24/outline'
import PropTypes from 'prop-types'
import { Fragment } from 'react'
import { useTranslation } from 'react-i18next'
import { useLocation, useNavigate } from 'react-router'
import faq from '../constants/faq'
import trackEvents from '../constants/track-events'
import { trackEvent } from '../helpers/analytics'
import Button from './tailwind/Button'

const HelpSupportSlideover = ({
  isVisibleHelpAndSupport,
  setIsVisibleHelpAndSupport
}) => {
  const { t } = useTranslation()
  const { pathname, hash } = useLocation()
  const currentPathnameHash = `${pathname}${hash}`

  const navigate = useNavigate()

  const articles = Object.entries(faq).filter((e) =>
    currentPathnameHash.includes(e[0])
  )

  return (
    <Transition show={isVisibleHelpAndSupport} as={Fragment}>
      <Dialog
        as='div'
        className='relative z-10'
        onClose={setIsVisibleHelpAndSupport}
      >
        <TransitionChild
          as={Fragment}
          enter='ease-in-out duration-500'
          enterFrom='opacity-0'
          enterTo='opacity-100'
          leave='ease-in-out duration-500'
          leaveFrom='opacity-100'
          leaveTo='opacity-0'
        >
          <div className='fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity' />
        </TransitionChild>

        <div className='fixed inset-0 overflow-hidden'>
          <div className='absolute inset-0 overflow-hidden'>
            <div className='pointer-events-none fixed inset-y-0 right-0 flex max-w-full pl-10'>
              <TransitionChild
                as={Fragment}
                enter='transform transition ease-in-out duration-500 sm:duration-700'
                enterFrom='translate-x-full'
                enterTo='translate-x-0'
                leave='transform transition ease-in-out duration-500 sm:duration-700'
                leaveFrom='translate-x-0'
                leaveTo='translate-x-full'
              >
                <DialogPanel className='pointer-events-auto w-screen max-w-md'>
                  <div className='flex h-full flex-col overflow-y-scroll bg-white py-6 shadow-xl'>
                    <div className='px-4 sm:px-6'>
                      <div className='flex items-start justify-between'>
                        <DialogTitle className='text-xl font-semibold leading-6 text-gray-900'>
                          {t('helpandsupport.title')}
                        </DialogTitle>
                        <div className='ml-3 flex h-7 items-center'>
                          <button
                            type='button'
                            className='relative rounded-md bg-white text-gray-400 hover:text-gray-500 focus:outline-none focus:ring-2 focus:ring-blue-500 focus:ring-offset-2'
                            onClick={() => setIsVisibleHelpAndSupport(false)}
                          >
                            <span className='absolute -inset-2.5' />
                            <span className='sr-only'>Close panel</span>
                            <XMarkIcon className='h-6 w-6' aria-hidden='true' />
                          </button>
                        </div>
                      </div>
                    </div>
                    <div className='relative mt-6 flex-1 px-4 sm:px-6'>
                      {articles.length > 0 && articles[0][1].length > 0 && (
                        <div className='pb-6'>
                          {articles[0][1]?.map((a, index) => (
                            <a
                              key={index}
                              target='_blank'
                              rel='noreferrer'
                              href={a.link}
                              className='mb-3 block text-sm font-medium text-blue-500 hover:underline'
                            >
                              {a.de}
                            </a>
                          ))}
                        </div>
                      )}
                      <div className='hidden py-6'>
                        <h3 className='text-lg font-semibold text-gray-900'>
                          {t('helpandsupport.video.headline')}
                        </h3>
                        <div className='mt-2 flex w-full flex-row'>
                          <video
                            onPlay={() =>
                              trackEvent(
                                trackEvents.CLICK_PLAY_VIDEO_HELP_SUPPORT
                              )
                            }
                            controls
                            className='h-auto w-6/12 rounded-lg'
                            type='video/mp4'
                            src='https://weur.r2.dubb.com/videos/2024-02-01/e19517dcf0d492b3bb6be809c4114780/720p_SFey.mp4'
                          >
                            Your browser does not support the video tag.
                          </video>
                          <div className='ml-3'>
                            <a
                              href='https://dubb.com/v/MFMH37'
                              rel='noreferrer'
                              target='_blank'
                              className='font-medium text-blue-500 hover:underline'
                            >
                              {t('helpandsupport.video.title')}
                            </a>
                            <p className='mt-2 text-sm text-gray-900'>
                              {t('helpandsupport.video.description')}
                            </p>
                          </div>
                        </div>
                      </div>
                      <div className='py-6'>
                        <h3 className='text-lg font-semibold text-gray-900'>
                          {t('helpandsupport.contactus.title')}
                        </h3>
                        <p className='mt-1 w-9/12 text-base text-gray-900'>
                          {t('helpandsupport.contactus.description')}
                        </p>
                        <Button.SecondaryLG
                          onClick={() => {
                            trackEvent(trackEvents.CLICK_SUPPORT_HELP_SUPPORT)
                            setIsVisibleHelpAndSupport(false)
                            navigate('/support')
                          }}
                          text={t('helpandsupport.gotosupport')}
                          className='mt-4'
                        />
                      </div>
                    </div>
                  </div>
                </DialogPanel>
              </TransitionChild>
            </div>
          </div>
        </div>
      </Dialog>
    </Transition>
  )
}

HelpSupportSlideover.propTypes = {
  isVisibleHelpAndSupport: PropTypes.bool.isRequired,
  setIsVisibleHelpAndSupport: PropTypes.func.isRequired
}

export default HelpSupportSlideover
