import PropTypes from 'prop-types'
import { useMemo, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { useNavigate } from 'react-router'

import {
  getSmartPredictMessages,
  getSmartPredictStates
} from '../../constants/smart-predict'
import trackEvents from '../../constants/track-events'
import { useNotificationContext, useUserContext } from '../../context'
import { mutation, query } from '../../graphql'
import { classNames } from '../../helpers'
import { trackEvent } from '../../helpers/analytics'
import { useCreateLog } from '../../hooks/use-create-log'
import Alert from '../alert'
import RadioGroup from '../radio-group'
import RadioInputGroup from '../radio-input-group'
import Button from '../tailwind/Button'
import SlideoverWithTitleAndActions from '../tailwind/slideover-with-title-and-actions'
import Toggle from '../tailwind/toggle'

const TRANSLATION_LOCATION = 'components.career.set_space_state.'

const SetSpaceState = ({
  spaces,
  updateHandler,
  open,
  setOpen,
  setIsCustomerEffortScoreVisible
}) => {
  const { t } = useTranslation()
  const { success } = useNotificationContext()
  const { active_space } = useUserContext()

  const navigate = useNavigate()

  const { createSpaceHistoryLog } = useCreateLog()

  const [isLoading, setIsLoading] = useState(false)
  const [sendMailEnabled, setSendMailEnabled] = useState(false)

  const lastApplicantStatus = useMemo(() => {
    if (!spaces.length || spaces.length > 1) return
    if (!spaces[0].logs) return

    // if only one space is selected from /space/:id
    const result = spaces[0].logs
      .map((log) => ({ ...log, data: JSON.parse(log.data) }))
      .filter(({ data }) => data.context === 'APPLICANT_STATUS')
      .sort((a, b) => new Date(a.createdAt) - new Date(b.createdAt))
      .pop() // .filter creates a new array

    return result
  }, [spaces])

  const spaceStates = getSmartPredictStates().map((state) => ({
    ...state,
    label: t(state.label),
    description: t(state.description)
  }))

  const [selectedSpaceState, setSelectedSpaceState] = useState(
    lastApplicantStatus ? lastApplicantStatus.data.status : null
  )

  const spaceStateMessages = getSmartPredictMessages().map((option) => ({
    ...option,
    label: t(option.label),
    description: t(option.description)
  }))

  const [selectedSpaceStateMessage, setSelectedSpaceStateMessage] = useState(
    lastApplicantStatus && lastApplicantStatus.data.message
      ? lastApplicantStatus.data.message
      : spaceStateMessages.find((option) => option.default).id
  )

  const { displayOptions, displaySendMail } =
    spaceStates.find(({ id }) => id === selectedSpaceState) || {}

  const handleSave = async () => {
    setIsLoading(true)

    const spaceState = spaceStates.find(({ id }) => id === selectedSpaceState)
    const spaceStateOption = spaceStateMessages.find(
      ({ id }) => id === selectedSpaceStateMessage
    )

    try {
      for (const space of spaces) {
        const { id, career_id, email } = space

        const input = { id }
        const event = {
          context: 'APPLICANT_STATUS',
          status: spaceState.id,
          option:
            spaceState.id === 'REJECTED' && sendMailEnabled && !!email
              ? 'VIA_EMAIL'
              : undefined,
          message: spaceState.displayOptions ? spaceStateOption.id : undefined
        }

        if (spaceState.id === 'HIRED' || spaceState.id === 'REJECTED') {
          input.application_status = spaceState.id
        }

        await mutation({ mutation: 'updateSpace', input })
        await createSpaceHistoryLog({ space_id: id, career_id, ...event })
      }

      // can only be enabled for REJECTED
      if (sendMailEnabled) {
        // space.email // comes from src/helpers/spaces-table-data.js
        const spaces_with_email = spaces.filter((space) => !!space.email)

        await Promise.all(
          spaces_with_email.map(
            ({ id: space_id, career_id, partner_id, email, language }) => {
              return query({
                query: 'inviteTalent',
                variables: {
                  space_id,
                  career_id,
                  partner_id,
                  email,
                  language,
                  email_template: 'reject'
                }
              })
            }
          )
        )
      }
    } catch (err) {
      setIsLoading(false)
      return
    }

    success(t(TRANSLATION_LOCATION + 'update_space_success'))
    setIsLoading(false)
    setOpen(false)
    updateHandler()

    const FEEDBACK_ID = `${active_space.id}-CUSTOMER-EFFORT-SCORE`
    const ces = await query({
      query: 'getFeedback',
      variables: { id: FEEDBACK_ID }
    })

    if (selectedSpaceState !== 'OPEN' && ces === null) {
      setTimeout(() => {
        setIsCustomerEffortScoreVisible(true)
      }, 400)
    }
  }

  // space.email // comes from src/helpers/spaces-table-data.js
  const spaces_with_email = spaces.filter((space) => !!space.email)

  return (
    <SlideoverWithTitleAndActions
      open={open}
      setOpen={() => {
        setOpen(false)
        trackEvent(trackEvents.ABORT_APPLICANT_STATUS)
      }}
      title={t(TRANSLATION_LOCATION + 'popup_title')}
      description={t(TRANSLATION_LOCATION + 'popup_description', {
        number: spaces.length
      })}
      actions={
        <div className='flex flex-shrink-0 justify-end space-x-2 px-4 py-4'>
          <Button.SecondaryXL
            text={t(TRANSLATION_LOCATION + 'popup_cancel_action')}
            onClick={() => {
              setOpen(false)
              trackEvent(trackEvents.ABORT_APPLICANT_STATUS)
            }}
          />
          <Button.PrimaryXL
            text={t(
              TRANSLATION_LOCATION +
                (sendMailEnabled
                  ? 'popup_confirm_with_email_action'
                  : 'popup_confirm_action')
            )}
            onClick={handleSave}
            isLoading={isLoading}
            disabled={!selectedSpaceState}
          />
        </div>
      }
    >
      <div className='px-8 py-4'>
        <div className='mt-4'>
          <h3 className='text-sm font-semibold text-gray-900'>
            {t(TRANSLATION_LOCATION + 'states_group_label')}
          </h3>
          <div className='mt-4 pl-2'>
            <RadioInputGroup
              group={spaceStates}
              selected={selectedSpaceState}
              setSelected={setSelectedSpaceState}
            />
          </div>
        </div>
        {displayOptions && (
          <>
            <div className='mt-6 h-px w-full bg-gray-200' />
            <div className='mt-6'>
              <h3 className='text-sm font-semibold text-gray-900'>
                {t(TRANSLATION_LOCATION + 'options_group_label')}
              </h3>
              <div className='mt-4 px-2'>
                <RadioGroup
                  group={spaceStateMessages}
                  selected={selectedSpaceStateMessage}
                  setSelected={setSelectedSpaceStateMessage}
                />
              </div>
            </div>
            {displaySendMail && spaces_with_email.length > 0 && (
              <>
                <div className='mt-6 h-px w-full bg-gray-200' />
                <div className='mt-6'>
                  <Toggle
                    label={t(
                      TRANSLATION_LOCATION +
                        (spaces.length === 1
                          ? 'send_email_toggle_label'
                          : 'send_emails_toggle_label'),
                      { number: spaces_with_email.length }
                    )}
                    enabled={sendMailEnabled}
                    setEnabled={setSendMailEnabled}
                  />
                </div>
                {spaces.length === 1 && (
                  <div
                    className={classNames(
                      'mb-8 mt-4',
                      sendMailEnabled ? 'cursor-pointer' : 'opacity-40'
                    )}
                    onClick={
                      sendMailEnabled
                        ? () => {
                            if (spaces[0].career_id) {
                              navigate(
                                `/career/${spaces[0].career_id}#assessment-settings`
                              )
                            } else {
                              navigate('/settings#templates')
                            }
                          }
                        : undefined
                    }
                  >
                    <div className='mt-1 text-sm font-semibold leading-6 text-blue-600 hover:text-blue-500'>
                      {t(TRANSLATION_LOCATION + 'edit_template_action')}
                      <span aria-hidden='true'> &rarr;</span>
                    </div>
                  </div>
                )}
                {spaces.length > 1 && sendMailEnabled && (
                  <div className='mb-8 mt-4'>
                    <Alert
                      type='info'
                      title={t(
                        TRANSLATION_LOCATION + 'send_emails_alert_title'
                      )}
                      description={t(
                        TRANSLATION_LOCATION + 'send_emails_alert_description'
                      )}
                    />
                  </div>
                )}
              </>
            )}
          </>
        )}
      </div>
    </SlideoverWithTitleAndActions>
  )
}

SetSpaceState.propTypes = {
  spaces: PropTypes.array.isRequired,
  updateHandler: PropTypes.func.isRequired,
  open: PropTypes.bool.isRequired,
  setOpen: PropTypes.func.isRequired,
  setIsCustomerEffortScoreVisible: PropTypes.func.isRequired
}

export default SetSpaceState
