import { useQuery } from '@tanstack/react-query'
import PropTypes from 'prop-types'
import { useState } from 'react'

import { useTranslation } from 'react-i18next'
import { BeatLoader } from 'react-spinners'
import { colors } from '../../constants/colors'
import trackEvents from '../../constants/track-events'
import { useNotificationContext } from '../../context'
import { query } from '../../graphql'
import { notifyBugsnag } from '../../helpers'
import { trackEvent } from '../../helpers/analytics'
import { useTalentsQuery } from '../../hooks/use-talents-query'
import Button from '../tailwind/Button'
import Modal from '../tailwind/Modal'
import Panel from '../tailwind/Panel'
import CompareSpaceDropdown from './space-radar-chart/compare-space-dropdown'
import RadarChartComplete from './space-radar-chart/radar-chart-complete'
import RadarChartPreview from './space-radar-chart/radar-chart-preview'

const queryAnswers = ({ owner }) => {
  const promise = new Promise((resolve, reject) => {
    query({
      query: 'helperControl',
      variables: { action: 'listAnswersWithAivyOutput', user_id: owner }
    }).then((response) => {
      try {
        const result = JSON.parse(
          response
        ).body.listAnswersWithAivyOutput.res.reduce(
          (object, item) => ({ ...object, [item.exam_id]: item }),
          {}
        )

        resolve(result)
      } catch (err) {
        reject(
          new Error(
            `${err.message} - listAnswersWithAivyOutput: ${JSON.stringify(
              response
            )}`
          )
        )
      }
    })
  })

  return promise
}

const SpaceRadarChart = ({ space: originSpace, career }) => {
  const { t } = useTranslation()
  const { error } = useNotificationContext()

  const { data } = useTalentsQuery()

  const spaces = (data?.spaces || []).filter(
    ({ id, context, progress }) =>
      id !== originSpace.id && context === 'TALENTPOOL' && progress === 100
  )

  const [compareSpace, setCompareSpace] = useState({})
  const [displayModal, setDisplayModal] = useState(false)
  const [searchSpaceQuery, setSearchSpaceQuery] = useState('')

  const {
    data: originAnswers,
    status: originStatus,
    error: originError
  } = useQuery({
    queryKey: ['answers-with-aivy-output-origin', originSpace.owner],
    queryFn: () => queryAnswers({ owner: originSpace.owner })
  })

  const {
    data: compareAnswers,
    status: compareStatus,
    error: compareError
  } = useQuery({
    queryKey: ['answers-with-aivy-output-compare', compareSpace.owner],
    queryFn: () => queryAnswers({ owner: compareSpace.owner }),
    enabled: !!compareSpace.owner
  })

  if (originStatus === 'error' || compareStatus === 'error') {
    error()
    notifyBugsnag(originError || compareError)
    return null
  }

  const filteredSpaces =
    searchSpaceQuery === ''
      ? spaces
      : spaces.filter((space) => {
          const value = `${space.id}/${space.name}/${space.identification}`
          return value.toLowerCase().includes(searchSpaceQuery.toLowerCase())
        })

  return (
    <>
      <Panel className='overflow-visible'>
        <div className='pb-5 sm:flex'>
          <div>
            <h2 className='text-lg font-medium leading-6 text-gray-900'>
              {t('space.radar_chart.title')}
            </h2>
          </div>
        </div>
        {originStatus === 'pending' ? (
          <div className='flex justify-center py-8'>
            <BeatLoader
              color={colors.primaryBlue}
              speedMultiplier={0.8}
              size={16}
            />
          </div>
        ) : (
          <>
            <div className='flex justify-center'>
              <RadarChartPreview
                space={{ ...originSpace, answers: originAnswers }}
              />
            </div>
            <div
              style={{ maxWidth: 256 }}
              className='relative mx-auto mt-4 flex flex-col gap-y-1'
            >
              <Button.PrimaryLG
                text={t('space.radar_chart.show_talent_action')}
                onClick={() => {
                  trackEvent(trackEvents.CLICK_VIEW_RADARCHART_TALENT)
                  setDisplayModal(true)
                }}
              />
              {originSpace.context === 'TALENTPOOL' && (
                <CompareSpaceDropdown
                  {...{
                    searchSpaceQuery,
                    setSearchSpaceQuery,
                    spaces,
                    filteredSpaces,
                    setCompareSpace,
                    setDisplayModal
                  }}
                />
              )}
            </div>
          </>
        )}
      </Panel>
      <Modal
        size='4xl'
        open={displayModal}
        setOpen={() => {
          setDisplayModal(false)
          setTimeout(() => setCompareSpace({}), 1000 * 0.8)
        }}
      >
        <div className='mt-8' />
        {!compareSpace.owner && originStatus === 'success' && (
          <>
            <RadarChartComplete
              spaces={[{ ...originSpace, answers: originAnswers }]}
              career={career?.app_settings ? career : null}
            />
            <p className='mt-8 text-center text-sm italic text-gray-700'>
              {t('radar_chart.show_hide_hint')}
            </p>
          </>
        )}

        {compareSpace.owner && compareStatus === 'pending' && (
          <div className='flex items-center justify-center p-64'>
            <BeatLoader />
          </div>
        )}

        {originStatus === 'success' && compareStatus === 'success' && (
          <>
            <RadarChartComplete
              spaces={[
                { ...originSpace, answers: originAnswers },
                { ...compareSpace, answers: compareAnswers }
              ]}
            />
            <p className='mt-8 text-center text-sm italic text-gray-700'>
              {t('radar_chart.show_hide_hint')}
            </p>
          </>
        )}
      </Modal>
    </>
  )
}

SpaceRadarChart.propTypes = {
  space: PropTypes.object.isRequired,
  career: PropTypes.object
}

export default SpaceRadarChart
