import PropTypes from 'prop-types'

import { useLanguageContext } from '../../../context'
import { useChartData } from '../../../hooks/use-chart-data'
import RadarChart from '../../react-chart/radar-chart'

const RadarChartComplete = ({ spaces, career }) => {
  const { language } = useLanguageContext()

  const { extractChartData, options } = useChartData({
    spaces,
    careers: career ? [career] : undefined
  })

  const chartData = extractChartData()

  return (
    <div className='relative h-auto w-full'>
      <RadarChart
        data={{
          labels: chartData.dimensions.map(
            ({ property }) => property[language]
          ),
          datasets: [
            ...chartData.spaces.map((space) => ({
              ...space,
              data: space.data.map(({ result }) => result)
            })),
            ...chartData.careers.map((career) => ({
              ...career,
              data: career.data.map(({ optimal }) => optimal)
            }))
          ]
        }}
        options={options}
      />
    </div>
  )
}

RadarChartComplete.propTypes = {
  spaces: PropTypes.array.isRequired,
  career: PropTypes.object
}

export default RadarChartComplete
