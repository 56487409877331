import { CalendarDaysIcon, PaperClipIcon } from '@heroicons/react/24/solid'
import { format } from 'date-fns'
import PropTypes from 'prop-types'
import { forwardRef, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { useNavigate } from 'react-router'
import { BeatLoader } from 'react-spinners'

import MatchingProfilePreview from '../../assets/images/strengthprofile-preview.png'
import StrengthProfilePreview from '../../assets/images/strengthprofile-preview1.png'
import { colors } from '../../constants/colors'
import trackEvents from '../../constants/track-events'
import {
  useLanguageContext,
  useNotificationContext,
  usePartnerContext
} from '../../context'
import { mutation, query } from '../../graphql'
import {
  classNames,
  getBackgroundColorByMatchingScore,
  getInviteToAssessmentLink,
  notifyBugsnag
} from '../../helpers'
import { trackEvent } from '../../helpers/analytics'
import { useCreateLog } from '../../hooks/use-create-log'
import Datepicker from '../react-datepicker/datepicker'
import State from '../state'
import Button from '../tailwind/Button'
import Panel from '../tailwind/Panel'
import SpaceDetailItem from './space-detail-item'

const CalendarButton = forwardRef(({ date, onClick }, ref) => (
  <button
    className='text-md mt-1 flex items-center font-medium text-gray-900 hover:text-blue-600'
    onClick={onClick}
    ref={ref}
  >
    <CalendarDaysIcon className='mr-1.5 h-5 w-5' />
    {format(date, 'dd.MM.yyyy')}
  </button>
))

CalendarButton.propTypes = {
  date: PropTypes.object.isRequired,
  onClick: PropTypes.func
}

CalendarButton.defaultProps = {
  onClick: () => undefined
}

const SpaceDetailsAssessment = ({
  space,
  refetchSpace,
  career,
  spaceActions
}) => {
  const { t } = useTranslation()
  const { partner } = usePartnerContext()
  const { language } = useLanguageContext()
  const { success, error } = useNotificationContext()

  const navigate = useNavigate()
  const { createSpaceHistoryLog } = useCreateLog()

  const {
    id: space_id,
    career_id: space_career_id,
    // partner_user_id,
    progress,
    owner,
    score,
    status,
    expiresAt,
    language: space_language
  } = space

  const { assessment_status, smart_predict_status } = status

  const {
    id: career_id,
    title: c_title,
    external_custom_id,
    archived
  } = career || {}

  const career_title_with_archived = archived
    ? t('talents.career_archived_title', { career_title: c_title })
    : c_title

  const career_title = external_custom_id
    ? `${career_title_with_archived} | ${external_custom_id}`
    : career_title_with_archived

  const { setSpacesStateAvailable, setDisplaySetSpaceState } = spaceActions

  const [copied, setCopied] = useState(false)
  const [isUpdatingExpiresAt, setIsUpdatingExpiresAt] = useState(false)

  const copyInviteLinkToClipboard = () => {
    navigator.clipboard.writeText(
      getInviteToAssessmentLink({ space_id, language: space_language })
    )
    setCopied(true)
    setTimeout(() => setCopied(false), 1000 * 3)
  }

  const updateExpiresAt = async (date) => {
    setIsUpdatingExpiresAt(true)

    const event = { context: 'SYSTEM', status: 'RESET_EXPIRED' }

    date.setHours(23)
    date.setMinutes(59)
    date.setSeconds(59)

    try {
      await mutation({
        mutation: 'updateSpace',
        input: { id: space_id, expiresAt: date.toISOString() }
      })
      await createSpaceHistoryLog({
        space_id,
        career_id: space_career_id,
        ...event
      })

      await refetchSpace()
      success(t('space_actions.reset_expired_success'))
      trackEvent(trackEvents.RESET_EXPIRED_TALENTS)
    } catch (err) {
      console.error(err)
    } finally {
      setIsUpdatingExpiresAt(false)
    }
  }

  const assessment = {
    basics: [
      {
        available: !!career_id,
        term: t('pages.talent.space_details_career_label'),
        details: career_title,
        onClick: () => navigate(`/career/${career_id}`)
      },
      {
        id: 'score',
        available: progress === 100 && career_id,
        term: t('pages.talent.space_details_score_label', {
          career_title
        }),
        details: (
          <div
            style={{
              height: 32,
              width: 60,
              backgroundColor: getBackgroundColorByMatchingScore(score)
            }}
            className={`flex items-center justify-center rounded font-bold text-white`}
          >
            {partner.isScoreVisible === true ? score : ''}
          </div>
        )
      },
      {
        available: !!expiresAt,
        term: t('pages.talent.space_details_expires_at_label'),
        details: isUpdatingExpiresAt ? (
          <BeatLoader color={colors.darkGrey} speedMultiplier={0.8} size={10} />
        ) : (
          <Datepicker
            selected={new Date(expiresAt)}
            onChange={updateExpiresAt}
            minDate={new Date()}
            customInput={<CalendarButton date={new Date(expiresAt)} />}
          />
        )
      },
      {
        available: true,
        term: t('pages.talent.space_details_via_link_label'),
        details: (
          <div
            className='flex items-center truncate pr-4'
            onClick={copyInviteLinkToClipboard}
          >
            <span className='text-md truncate font-medium text-gray-900 hover:cursor-pointer'>
              {getInviteToAssessmentLink({
                space_id,
                language: space_language
              })}
            </span>
            {copied ? (
              <svg
                xmlns='http://www.w3.org/2000/svg'
                className='h-5 w-7 flex-shrink-0 pl-2 hover:cursor-pointer'
                fill='none'
                viewBox='0 0 24 24'
                stroke='#111827'
              >
                <path
                  strokeLinecap='round'
                  strokeLinejoin='round'
                  strokeWidth={2}
                  d='M5 13l4 4L19 7'
                />
              </svg>
            ) : (
              <svg
                xmlns='http://www.w3.org/2000/svg'
                className='h-5 w-7 flex-shrink-0 pl-2 hover:cursor-pointer'
                fill='none'
                viewBox='0 0 24 24'
                stroke='#111827'
              >
                <path
                  strokeLinecap='round'
                  strokeLinejoin='round'
                  strokeWidth={2}
                  d='M8 16H6a2 2 0 01-2-2V6a2 2 0 012-2h8a2 2 0 012 2v2m-6 12h8a2 2 0 002-2v-8a2 2 0 00-2-2h-8a2 2 0 00-2 2v8a2 2 0 002 2z'
                />
              </svg>
            )}
          </div>
        )
      }
    ].filter(({ available }) => available)
  }

  const attachments = [
    {
      id: 'MATCHINGPROFILE',
      available: progress === 100 && career_id,
      file: t('pages.talent.matching_profile'),
      addition: t('pages.talent.matching_profile_addition', {
        career_title
      }),
      variables: {
        career_id,
        user_id: owner,
        space_id,
        type: career?.diagnostic_version === 2 ? 'MATCHINGPROFILE' : undefined,
        language
      },
      background: '#2C46B2',
      imageSrc: MatchingProfilePreview,
      event: trackEvents.DOWNLOAD_MATCHING_PROFILE_TALENTDETAIL
    },
    {
      id: 'TALENT_PROFILE',
      available: progress > 0, // Show after the first game
      file: t('pages.talent.strength_profile'),
      variables: { user_id: owner, language, type: 'TALENTPROFILE' },
      background: '#fe79a7',
      imageSrc: StrengthProfilePreview,
      event: trackEvents.DOWNLOAD_STRENGTH_PROFILE_TALENTDETAIL
    }
  ].filter(({ available }) => available)

  const generatePdf = async ({ variables, event }) => {
    trackEvent(event)

    const pdfDocument = window.open('', '_blank')
    pdfDocument.document.write(t('pages.talent.pdf_preparation'))

    try {
      const pdf = await query({ query: 'generatePdf', variables })
      pdfDocument.location.href = JSON.parse(pdf).headers.Location
      success(t('pages.talent.pdf_success'))
    } catch (err) {
      error()
      notifyBugsnag(err)
      pdfDocument.close()
    }
  }

  return (
    <Panel>
      <section
        className='md:h-full'
        aria-labelledby='applicant-information-title'
      >
        <div className='md:h-full'>
          <div className='pb-5 sm:flex'>
            <div>
              <h2
                id='applicant-information-title'
                className='text-lg font-medium leading-6 text-gray-900'
              >
                {t('pages.talent.space_details_assessment_header')}
              </h2>
              <p className='mt-1 max-w-2xl text-sm text-gray-500'>
                {t('pages.talent.space_details_assessment_description')}
              </p>
            </div>
            <div
              className={classNames(
                'my-2 text-sm text-gray-900 sm:my-auto sm:ml-auto',
                setSpacesStateAvailable && 'cursor-pointer'
              )}
              onClick={
                setSpacesStateAvailable
                  ? () => setDisplaySetSpaceState(true)
                  : undefined
              }
            >
              {progress === 100 ? (
                <State
                  text={t(smart_predict_status.status.label)}
                  color={smart_predict_status.status.color}
                />
              ) : (
                <State
                  text={t(assessment_status.status.label, { progress })}
                  color={assessment_status.status.color}
                />
              )}
            </div>
          </div>
          <div className='border-t border-gray-300 pt-5'>
            <dl className='grid grid-cols-1 gap-4 sm:grid-cols-2'>
              {assessment.basics.map(({ term, details, onClick }, index) => (
                <SpaceDetailItem key={index} {...{ term, details, onClick }} />
              ))}
            </dl>
            {attachments.length > 0 && (
              <div className='mt-4 sm:col-span-2'>
                <dt className='text-sm text-gray-500'>
                  {t('pages.talent.attachments')}
                </dt>
                <dd className='mt-1 text-sm font-medium text-gray-900'>
                  <ul role='list' className='flex flex-row flex-wrap'>
                    {attachments.map((attachment, index) => (
                      <div
                        key={index}
                        onClick={() => generatePdf(attachment)}
                        style={{
                          width: 172,
                          height: 243,
                          background: attachment.background
                        }}
                        className='relative mr-4 mt-2 flex h-16 w-8 cursor-pointer flex-col items-center rounded-xl px-6 hover:opacity-70'
                      >
                        <div
                          style={{ height: '60%' }}
                          className='flex w-full items-center justify-center'
                        >
                          <img
                            style={{ maxWidth: 100 }}
                            src={attachment.imageSrc}
                          />
                        </div>

                        <div className='absolute bottom-6 flex flex-col items-center'>
                          <span className='mb-2 text-lg font-medium text-white'>
                            {attachment.file}
                          </span>
                          <Button.SecondaryBase text='Download' />
                        </div>

                        <PaperClipIcon
                          className='absolute right-2 top-2 h-5 w-5 flex-shrink-0 text-white'
                          aria-hidden='true'
                        />
                      </div>
                    ))}
                  </ul>
                </dd>
              </div>
            )}
          </div>
        </div>
      </section>
    </Panel>
  )
}

SpaceDetailsAssessment.propTypes = {
  space: PropTypes.object.isRequired,
  refetchSpace: PropTypes.func.isRequired,
  career: PropTypes.object,
  spaceActions: PropTypes.object.isRequired
}

SpaceDetailsAssessment.defaultProps = {
  career: null
}

export default SpaceDetailsAssessment
